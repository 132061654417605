#headerParentDiv{
    display:flex;
    flex-direction:column;
}
#topHeaderDiv{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100vw;
    height:200px;
    background-color:black;
}
#leftHeaderDiv{
    display:flex;
}
#headerMyName{
    color:white;
    margin-left:50px;
    position:relative;
    bottom:20px;
}
#rightHeaderDiv{
    margin-right:25px;
}
#headerLogo{
    border-radius:80px;
    height:150px;
    position:relative;
    bottom:15px;
}
#headerLogo:hover{
    cursor:pointer;
}
#bottomHeaderDiv{
    display:flex;
    justify-content:center;
}
@media(max-width:300px){
    #headerMyName{
        font-size:25px;
        margin-left:25px;
    }   
    #headerLogo{
        height:100px;
    }
}