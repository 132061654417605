#none{
    display:none;
}
#bioDiv{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    background-color:lightgray;
    height:4150px;
}
#introDiv{
    height:60vw;
    width:80vw;
    min-width:200px;
    max-height:500px;
    margin-bottom:80px;
}
#div1{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    width:90vw;
    background-color:white;
    padding-top:50px;
}
#div2{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    width:90vw;
    background-color:white;
}
#div3{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    width:90vw;
    background-color:white;
    padding-top:50px;
}
.paragraphs{
    width:60vw;
    font-weight:600;
    font-size: 20px;
}
.mePics{
    height:200px;
    width:200px;
}
@media(max-width:300px){
    #bioDiv{
        height:5250px;
    }
}
@media only screen and (min-device-width:500px) and (max-device-width:1030px){
    #bioDiv{
        height:2850px;
    }   
}
@media(min-width:1100px){
    #bioDiv{
        height:2000px;
    }
    #div1{
        flex-direction:column;
    }
    #div2{
        flex-direction:column;
    }
    #div3{
        flex-direction:column;
    }
    .mePics{
        margin-top:20px;
        margin-bottom:20px;
    }
    .paragraphs{
        width:80vw;
    }
}
@media only screen and (min-device-width:1100px) and (max-device-width:10000px){
    #bioDiv{
        height:2200px;
    }
}
