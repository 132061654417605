#navBtnsParentDiv{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    position:relative;
    bottom:40px;
    width:100vw;
    height:120px;
    padding-top:5px;
    padding-bottom:15px;
    padding-left:5px;
    padding-right:5px;
}
#mainNavDiv{
    display:flex;
    justify-content:space-around;
    align-items:center;
    width:100vw;
    height:40px;
    background-color:rgb(89,35,9);
    padding-top:5px;
    padding-bottom:5px;
}
.darkNavDiv{
    display:flex;
    align-items:center;
    height:40px;
    background-color:black;
    border-radius:20px;
}
.NavBtns{
    color:white;
    text-decoration:underline;
    margin-left:10px;
    margin-right:10px;
}
.NavBtns:hover{
    cursor:pointer;
}
#menuDiv{
    display:flex;
    flex-direction:column;
    align-items:center;
    height:80px;
    width:100vw;
}
#blankSpaceDiv{
    height:80px;
    width:100vw;
    background-color:white;
}
.menuRowDivs{
    display:flex;
    justify-content:space-around;
    height:40px;
    width:100vw;
    padding-left:5px;
    padding-right:5px;
    text-align:center;
}
.menuCell{
    display:flex;
    align-items:center;
    color:white;
}
#menuItems{
    display:flex;
    justify-content:center;
    background-color:rgb(89,35,9);
    width:140px;
}
#menuItems:hover{
    cursor:pointer;
    border-color:black;
    border-style:solid;
    border-width:3px;
}