#parentFooterDiv{
    height:350px;
    width:100vw;
    background-color:black;
    display:flex;
    justify-content:space-evenly;
}
.footerSections{
    color:white;
    font-weight:600;
}
.footerText{
    color:white;
    max-width:30vw;
}
.socialIcons{
    height:30px;
    width:30px;
}
.socialIcons:hover{
    cursor:pointer;
    height:33px;
    width:33px;
    filter:brightness(80%);
}
#column1{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    width:33.333vw;
    align-items:center;
}
#column2{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    width:33.333vw;
    align-items:center;
}
#column3{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:33.333vw;
    align-items:center;
}
#footerLogo{
    height:130px;
    width:130px;
}
#footerLogo:hover{
    cursor:pointer;
}
.contactUsDiv{
    display:flex;
    flex-direction:column;
    align-items:center;
}
.contactText{
    margin-top:20px;
    margin-bottom:0px;
}
@media(max-width:300px){
    #footerLogo{
        height:80px;
        width:80px;
    }
    #column1{
        width:22vw;
    }
    #column3{
        margin-left:5px;
    }
}