#parentDiscoverDiv{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    padding-top:15px;
    padding-bottom:15px;
    background:url("./images/discoverBackPic.jpg");
}
.discoverDivs{
    display:flex;
    justify-content:center;
    align-items:center;
    height:40vh;
    width: 40vw;
    filter:opacity(70%);
    border-style:solid;
    border-width:6px;
}
.discoverDivs:hover{
    filter:opacity(96%);
    cursor:pointer;
}
#disDiv0{
    background-color:peru;
    color:black;
    text-decoration:none;
}
#disDiv1{
    background-color:lightgray;
    color:black;
    text-decoration:none;
}
#disDiv2{
    background-color:lightgoldenrodyellow;
    color:black;
    text-decoration:none;
}
#disDiv3{
    background-color:lightsalmon;
    color:black;
    text-decoration:none;
}
#disDiv4{
    background-color:lightblue;
    color:black;
    text-decoration:none;
}
#disDiv5{
    background-color:wheat;
    color:black;
    text-decoration:none;
}
.disText{
    font-size:3vh;
    font-weight:700;
    text-align:center;
}