#articlesPageParentDiv{
    background-color:grey;
    display:flex;
    align-items:center;
    flex-direction:column;
    width:100vw;
    height:17650px;
}
#psychologyParentDiv{
    width:100vw;
    background-color:rgb(209, 223, 223);
}
#psychologyDiv{
    width:100vw;
    display:flex;
    justify-content:center;
}
#psychologyTitle{
    font-size:30px;
    margin-top:5px;
    margin-bottom:5px;
}
#articlesMenuDiv{
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
}
.artMenuTxt{
    font-weight:500;
    font-size:20px;
}
.articleVideos{
    height:60vw;
    width:80vw;
    min-width:200px;
    max-height:500px;
}
.sectionTitlesDiv{
    display:flex;
    justify-content:center;
    font-size:25px;
    text-align:center;
    text-decoration:underline;
}
.sectionInfoDivParent{
    display:flex;
    justify-content:center;
    width:100vw;
}    
.sectionInfoDiv{
    width:60vw;
    display:flex;
    flex-direction:column;
    font-weight:600;
    font-size: 20px;
}
#articleIdeasDiv2{
    display:flex;
    align-items:center;
    justify-content:center;
}
#articleIdeasTextDiv{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:60vw;

}
#wormParentDiv{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    height:2100px;
    background-color:rgb(164, 135, 91);
}
.articleVidsDivs{
    height:60vw;
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
}
.textContentDivs{
    display:flex;
    flex-direction:column;
    text-wrap:pretty;
}
#abusedDiv{
    height:3650px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:left;
    background-color:rgb(164, 135, 91);
}
.abuseTitleDivs{
    width:100vw;
    display:flex;
    justify-content:center;
}
.abusedTitles{
    font-size:25px;
    text-decoration:underline;
    text-align:center;
}
.abusedDescriptionDivs{
    width:100vw;
    display:flex;
    justify-content:center;
}
.abusedDescriptions{
    width:60vw;
    font-size:20px;
    font-weight:600;
}
.topBtnParentDiv{
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
}
.topBtnDiv:hover{
    cursor:pointer;
    border-width:4px;
}
.topBtnDiv{
    color:white;
    height:40px;
    width:130px;
    background-color:rgb(89,35,9);
    border-radius:10px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    font-weight:500;
    border-color:black;
    border-width:3px;
    border-style:solid;
}
#objectVsLocationDiv{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    height:3450px;
    background-color:white;
}
#trainersDiv{
    background-color:rgb(195, 233, 245);
    height:2115px;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
}
#startEndLightDiv{
    background-color:rgb(195, 233, 245);
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:left;
    height:3850px;
}
#articleIdeasDiv{
    background-color:rgb(195, 164, 118);
    display:flex;
    flex-direction:column;
    width:100vw;
}
#ownerSenseDiv{
    height:1500px;
    background-color:white;
    display:flex;
    flex-direction:column;
    justify-content:center;
}
#ownerQuotes{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
@media(max-width:300px){
    #articlesPageParentDiv{
        height:23300px;
    }
    #articleIdeasDiv{
        height:900px;
    }
    #wormParentDiv{
        height:2600px;
    }
    #objectVsLocationDiv{
        height:4750px;
    }
    #abusedDiv{
        height:4850px;
    }
    #startEndLightDiv{
        height:5450px;
    }
    #trainersDiv{
        height:2700px;
    }
    #ownerSenseDiv{
        height:1750px;
    }
}
@media only screen and (min-device-width:500px) and (max-device-width:1030px){
    #articlesPageParentDiv{
        height:12500px;
    }
    #wormParentDiv{
        height:1650px;
    }
    #objectVsLocationDiv{
        height:2300px;
    }
    #abusedDiv{
        height:2450px;
    }
    #startEndLightDiv{
        height:2525px;
    }
    #trainersDiv{
        height:1600px;
    }
    #ownerSenseDiv{
        height:1300px;
    }
}
@media(min-width:1100px){
    #articlesPageParentDiv{
        height:9850px;
    }
    #wormParentDiv{
        height:1400px;
        justify-content:center;
    }
    .articleVidsDivs{
        height:535px;
    }
    #objectVsLocationDiv{
        height:1700px;
    }
    #abusedDiv{
        height:1850px;
    }
    #startEndLightDiv{
        height:1800px;
    }
    #trainersDiv{
        height:1300px;
    }
    #ownerSenseDiv{
        height:1150px;
    }
}