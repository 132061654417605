#none{
    display:none;
}
#middleDiv{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
}
#steedPic{
    display:flex;
    justify-content:center;
    max-width:350px;
    margin-bottom:30px;
}
#seperateDiv{
    height:8px;
    width:100vw;
    background-color:black;
}
@media(max-width:300px){
    #steedPic{
        width:270px;
    }
}