#horsesPageParentDiv{
    height:500px;
    width:100vw;
    display:flex;
    align-items:center;
    flex-direction:column;
    background-color:rgb(168, 116, 90);
}
#horsesPageDescriptionDiv{
    display:flex;
    justify-content:space-evenly;
    width:100vw;
    background-color:silver;
}
#youtubeLinkDiv{
    height:70px;
    width:100vw;
    background-color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:25px;
    margin-top:30px;
}
#youtubeLink{
    color:white;
}
#progressText{
    font-size:25px;
    text-align:center;
}
.playlists{
    width:80vw;
    max-width:400px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.listText{
    color:white;
    font-size:25px;
    text-align:center;
}
.listText:hover{
    filter:brightness(70%);
    cursor:pointer;
}
.horsesVideos{
    height:35vw;
    width:35vw;
    max-height:160px;
    max-width:160px;
    border-radius:30px;
    margin-top:10px;
    margin-left:5px;
    margin-right:5px;
}