#none{
    display:none;
}
#trainingParentDiv{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    height:3550px;
    width:100vw;
    background-color:lightgrey;
}
#allExercisesVid{
    height:60vw;
    width:80vw;
    min-width:200px;
    max-height:500px;
    margin-bottom:80px;
}
#whiteContainer{
    height:auto;
    width:90vw;
    background-color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-top-color:black;
    border-top-style:solid;
    border-top-width:3px;
    border-bottom-color:black;
    border-bottom-width:3px;
    border-bottom-style:solid;
}
.programRows{
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    width:90vw;
    border-color:black;
    border-width:3px;
    border-style:solid;
    border-left-style:none;
    border-right-style:none;
}
.leftRowDivs{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:30vw;
}
.leftRowText{
    text-align:center;
    width:100px;
    font-size:25px;
}
.rightRowDivs{
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
    width:40vw;
    min-width:200px;
}
.videoPics{
    height:50vw;
    width:50vw;
    max-height:200px;
    max-width:200px;
    border-radius:30px;
}